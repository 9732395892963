<template>
	<div class="top_menu">
		<span>
			Email : <a href="mailto:contact@alfredsms.fr">contact@alfredsms.fr</a>
		</span>
		<span>
			Téléphone : 06 77 00 37 01
		</span>
		<span>
			<router-link :to="{ name: 'home'}">
				<v-icon><img src="images/alfred-sms.png" /></v-icon>
				Accueil
			</router-link>
		</span>
		<span>
			<a href="/doc/" rel="noopener noreferrer" target="_blank">
				<v-icon>
					<img src="images/OpenBook.png" />
				</v-icon>
				Documentation
			</a>
		</span>
		<span v-show="connected">
			<router-link :to="{ name: 'account'}">
				<v-icon><img src="images/IconUser.png" /></v-icon>
				Compte
			</router-link>
			/
			<router-link :to="{ name: 'disconnection'}">
				<v-icon><img src="images/IconUser.png" /></v-icon>
				Se déconnecter
			</router-link>
		</span>
		<span v-show="!connected">
			<router-link :to="{ name: 'connection'}">
				<v-icon><img src="images/IconUser.png" /></v-icon>Créer un compte / Se connecter
			</router-link>
		</span>
	</div>

	<div id="wrapper">

		<!-- Router -->
		<router-view />

		<!-- Footer -->
		<footer id="footer">
			<section>
				<h2>Qui sommes nous ?</h2>
				<p>Nous sommes quatre jeunes entrepreneurs et c’est en créant notre propre application qu’on s’est rendu compte du tarif élevé des SMS automatisés. On s’est donc renseigné sur la façon de créer ce service, pour nous dans un premier temps, puis nous avons décidé d’en faire profiter d’autres entrepreneurs, comme nous&nbsp;!</p>
				<ul class="actions">
					<li>
						<a href="/#/legal" class="button">Mentions légales</a>
					</li>
				</ul>
			</section>
			<section>
				<h2>Nous contacter</h2>
				<dl class="alt">
					<dt>Téléphone</dt>
					<dd>+33 6 77 00 37 01</dd>
					<dt>Email</dt>
					<dd><a href="mailto:contact@alfredsms.fr">contact@alfredsms.fr</a></dd>
					<dt>Adresse</dt>
					<dd>2 rue Clodion &bull; 54000 NANCY &bull; FRANCE</dd>
				</dl>
				<a class="alt brands icon fa-linkedin" href="https://www.linkedin.com/company/alfred-sms/">
					<span class="label">LinkedIn</span>
				</a>
			</section>
			<p class="copyright">&copy; AlfredSMS. Design: <a href="https://html5up.net">HTML5 UP</a>.</p>
		</footer>

	</div>
</template>

<style src="./assets/css/main.css" />

<script>
	import Api from '@/Api.js'

	Api.init('https://api-dev.alfredsms.fr/v1/')
	// Api.init('https://api.alfredsms.fr/v1/')

	export default {
		created() {
			let api = Api.get_instance()
			this.connected = api.has_token()

			api.register_handler('connected', () => {
				this.connected = true
			})
			api.register_handler('disconnected', () => {
				this.connected = false
				this.$router.push({name: 'home'})
			})
		},
		data() {
			return {
				connected: false
			}
		},
		name: 'AlfredSMS'
	}
</script>
