<template>
	<header id="header" />

	<!-- Nav -->
	<nav id="nav">
		<ul>
			<li>
				Mot de passe oublié
			</li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main">
		<section class="main">
			<v-form id="update-password" v-model="password.valid" @submit.prevent="update_password">
				<v-container>
					<v-row>
						<v-alert id="alert" closable :color="alert.color" :text="alert.message" :type="alert.type" v-if="alert.show" v-model="alert.show" />
					</v-row>
					<v-row>
						<v-text-field :append-icon="password.show ? 'mdi-eye' : 'mdi-eye-off'" clearable label="Nouveau mot de passe :" :type="password.show ? 'text' : 'password'" required variant="solo-filled" v-model="password.value" @click:append="password.show = !password.show" />
					</v-row>
					<v-row>
						<v-spacer />
						<v-btn :disabled="!password.valid" text="Modifier" type="submit" variant="elevated" v-show="!password.in_progress" />
						<v-btn disabled text="Modification en cours" variant="elevated" v-show="password.in_progress" />
						<v-spacer />
					</v-row>
				</v-container>
			</v-form>
		</section>
	</div>
</template>

<style>
#alert {
	margin-bottom: 1em;
}

#update-password {
	margin-left: auto;
	margin-right: auto;
	width: 60%;
}
</style>

<script>
import Api from '@/Api'

export default {
	data() {
		return {
			alert: {
				color: null,
				message: null,
				show: false,
				type: null
			},
			password: {
				in_progress: false,
				show: false,
				valid: false,
				value: null
			}
		}
	},
	methods: {
		show_error(msg) {
			this.alert.color = 'red'
			this.alert.message = msg
			this.alert.show = true
			this.alert.type = 'error'
		},
		show_success(msg) {
			this.alert.color = 'green'
			this.alert.message = msg
			this.alert.show = true
			this.alert.type = 'success'
		},
		update_password() {
			this.password.in_progress = true
			window.setTimeout(() => {
				let api = Api.get_instance()
				api.do_change_password(this.password.value, this.$route.params.token)
					.then(() => {
						this.password.value = null
						this.show_success('Le mot de passe a été modifié')
					})
					.catch(() => {
						this.show_error('Erreur lors de la modification du mot de passe')
					})
					.finally(() => {
						this.password.in_progress = false
					})
			}, 250)
		}
	},
	name: 'LostPasswordView'
}
</script>
